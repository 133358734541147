import { OidcService } from '@fnz/oidc-authentication';
import { NOT_MOUNTED, SingleSpaCustomEventDetail } from 'single-spa';

import { TokenExchange } from './index';
import { TokenExchangeRequest } from './types';

export class TokenExchangeManager {
  private exchanges = new Map<string, TokenExchange>();
  private tokenExchangeConfiguration: TokenExchangeRequest;
  constructor(
    private userManager: OidcService['userManager'],
    configuration: Partial<TokenExchangeRequest> = {},
  ) {
    this.tokenExchangeConfiguration = {
      ...configuration,
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    };
    this.unmountListener();
  }
  getTokenExchange(
    id: string,
    mfeExchangeConfiguration: Partial<TokenExchangeRequest> = {},
  ) {
    if (this.exchanges.has(id)) {
      return this.exchanges.get(id);
    }
    const exchange = new TokenExchange(this.userManager, {
      ...this.tokenExchangeConfiguration,
      ...mfeExchangeConfiguration,
    });
    this.exchanges.set(id, exchange);
    return exchange;
  }
  private unmountListener() {
    const listener = (evt: Event) => {
      const notMounted = (evt as CustomEvent<SingleSpaCustomEventDetail>).detail
        .appsByNewStatus[NOT_MOUNTED];
      notMounted.forEach((id) => {
        const tokenExchange = this.exchanges.get(id);
        if (tokenExchange) {
          tokenExchange.destroy();
          this.exchanges.delete(id);
        }
      });
    };
    window.addEventListener('single-spa:app-change', listener);
  }
}
