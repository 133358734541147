import { LinksManager } from './links-manager';
import { ApplicationLinkConfiguration } from './types';

export class ScopedLinksManager {
  private linksManager: LinksManager;
  private mfeId: string;
  constructor(linksManager: LinksManager, scopedMFEId: string) {
    this.linksManager = linksManager;
    this.mfeId = scopedMFEId;
  }

  getAllLinks() {
    return this.linksManager.getAllLinks();
  }

  getPublicLinkById(id: string) {
    return this.linksManager.getPublicLinkById(id);
  }

  getPublicLinksByMicrofrontendId(mfeId: string) {
    return this.linksManager.getPublicLinksByMicrofrontendId(mfeId);
  }
  addLinkfromMFE(
    id: string,
    link: Omit<ApplicationLinkConfiguration, 'basePath' | 'appId'>,
  ) {
    console.warn(
      'DOCK WARNING: addLinkfromMFE is deprecated and will be removed soon. Please use addLink instead.',
    );
    return this.linksManager.addLinkfromMFE(id, link);
  }
  addLink(link: Omit<ApplicationLinkConfiguration, 'basePath' | 'appId'>) {
    return this.linksManager.addLinkfromMFE(this.mfeId, link);
  }
}
