// addUserLoaded
import { OidcService } from '@fnz/oidc-authentication';

import { TokenExchangeRequest } from './types';

export class TokenExchange {
  private _accessToken: string = '';

  constructor(
    private userInfo: OidcService['userManager'],
    private exchangeRequest: TokenExchangeRequest,
  ) {
    this.userInfo.events.addUserLoaded(this.updateToken);
  }

  getToken() {
    return this._accessToken;
  }
  destroy() {
    this.userInfo.events.removeUserLoaded(this.updateToken);
    this._accessToken = '';
  }
  updateToken = async () => {
    const tokenEndpoint =
      await this.userInfo.metadataService.getTokenEndpoint();
    const user = await this.userInfo.getUser();
    if (tokenEndpoint && user) {
      const body = new URLSearchParams({
        ...this.exchangeRequest,
        subject_token: user.access_token,
      });
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        body,
      });
      const data = (await response.json()) as unknown as {
        access_token: string;
      };
      this._accessToken = data.access_token;
    }
  };
}
