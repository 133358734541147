import { LinkConfiguration } from '../links-manager';
import { BreadcrumbNodeConfig, BreadcrumbsManager } from './breadcrumb-manager';
import { BreadcrumbNode } from './types';

export class ScopedBreadcrumbsManager {
  private breadcrumbsManager: BreadcrumbsManager;
  private isExternal: boolean;

  constructor(breadcrumbsManager: BreadcrumbsManager, isExternal: boolean) {
    this.breadcrumbsManager = breadcrumbsManager;
    this.isExternal = isExternal;
  }

  setBreadcrumbs(
    breadcrumbsConfigs: BreadcrumbNodeConfig[],
    after: number = 0,
  ): void {
    if (this.isExternal) {
      console.warn(
        'setBreadcrumbs is not available for external microfrontends',
      );
      return;
    }
    return this.breadcrumbsManager.setBreadcrumbs(breadcrumbsConfigs, after);
  }

  private checkAndWarnForExternalLinks(
    config: BreadcrumbNodeConfig[] | LinkConfiguration,
    action: string,
  ): boolean {
    const hasDirectLink = Array.isArray(config)
      ? config.some((conf) => 'url' in conf)
      : 'url' in config;

    if (this.isExternal && hasDirectLink) {
      console.warn(
        `${action} breadcrumb with direct link is not available for external microfrontends`,
      );
      return true;
    }
    return false;
  }

  addBreadcrumbs(breadcrumbsConfigs: BreadcrumbNodeConfig[]): void {
    if (this.checkAndWarnForExternalLinks(breadcrumbsConfigs, 'Adding')) {
      return;
    }
    return this.breadcrumbsManager.addBreadcrumbs(breadcrumbsConfigs);
  }

  prependBreadcrumbs(breadcrumbsConfigs: BreadcrumbNodeConfig[]): void {
    if (this.checkAndWarnForExternalLinks(breadcrumbsConfigs, 'Prepending')) {
      return;
    }
    return this.breadcrumbsManager.prependBreadcrumbs(breadcrumbsConfigs);
  }

  getCurrentBreadcrumbs() {
    return this.breadcrumbsManager.getCurrentBreadcrumbs();
  }

  registerBreadcrumb(id: string, config: LinkConfiguration): void {
    if (this.checkAndWarnForExternalLinks(config, 'Registering')) {
      return;
    }
    return this.breadcrumbsManager.registerBreadcrumb(id, config);
  }
}
