const getOrigin = (baseURL: string): string => {
  if (baseURL.startsWith('http')) {
    return baseURL;
  }
  if (!baseURL.startsWith('/')) {
    console.warn(
      'CSS path is relative, using absolute instead. Path should defined either full url or absolute instead',
    );
  }
  return `${window.location.origin}/${baseURL.replace(/^\//, '')}`;
};
export class ThemeManager {
  private repository: Map<string, CSSStyleSheet> = new Map();
  private globalUrls: string[] = [];

  //TODO theme change mechanism

  public static baseURLPolyfill(cssText: string, baseURL: string) {
    // source https://github.com/systemjs/systemjs/blob/9647576d43294e938ddae8fe231beb62255f4e46/src/extras/module-types.js#L42
    // replaces url with baseURL version
    return cssText.replace(
      /url\(\s*(?:(["'])((?:\\.|[^\n\\"'])+)\1|((?:\\.|[^\s,"'()\\])+))\s*\)/g,
      function (match, quotes, relUrl1, relUrl2) {
        const u =
          (relUrl1 as unknown as string) || (relUrl2 as unknown as string);
        const resolveUrl = new URL(u, getOrigin(baseURL));
        return ['url(', quotes, resolveUrl.href, quotes, ')'].join('');
      },
    );
  }

  async addCSS(url: string | string[], global = false) {
    const urls = this.prepareUrls(typeof url === 'string' ? [url] : url);
    await Promise.all(urls.map((url) => this.creatAdopted(url, global)));
    if (global && url.length) {
      const newUrls = urls.filter((url) => !this.globalUrls.includes(url));
      this.globalUrls = [...this.globalUrls, ...urls];
      newUrls.forEach((u) => {
        const st = this.repository.get(u);
        if (st) {
          document.adoptedStyleSheets?.push(st);
        }
      });
    }
  }

  getStyles(urls: string[], includeGlobals = true): CSSStyleSheet[] {
    return [...(includeGlobals ? this.globalUrls : []), ...urls]
      .map((url) => {
        return this.repository.get(url);
      })
      .filter((stylesheet): stylesheet is CSSStyleSheet => !!stylesheet);
  }

  private prepareUrls(urls: string[]) {
    const uniqueStrings = [...new Set(urls)];
    return uniqueStrings.filter((url) => !(this.repository.has(url) || !url));
  }
  private processFontsDefinitions(
    styleSheet: CSSStyleSheet,
    cssUrl: string = '',
  ) {
    const fontsRules: CSSRule[] = Array.from(styleSheet.cssRules).filter(
      (rule) => rule.constructor.name === 'CSSFontFaceRule', //type is deprecated
    );
    if (fontsRules.length) {
      const fontStylesheet = new CSSStyleSheet();
      fontsRules.forEach((rule) => fontStylesheet.insertRule(rule.cssText));
      document.adoptedStyleSheets?.push(fontStylesheet);
      console.log('Adding fonts not from global css', cssUrl);
    }
  }
  private async creatAdopted(url: string, global = false) {
    if (this.repository.has(url)) {
      console.warn('CSS ', url, 'already has been added');
      return;
    }
    try {
      const constructedStylesheet = new CSSStyleSheet({ baseURL: url });
      const response = await fetch(url);
      let cssText = await response.text();
      cssText = ThemeManager.baseURLPolyfill(cssText, url);
      await constructedStylesheet.replace(cssText);
      if (!global) {
        this.processFontsDefinitions(constructedStylesheet, url);
      }
      const rootIndex = Array.from(
        constructedStylesheet.cssRules || [],
      ).findIndex((rule) => (rule as CSSStyleRule).selectorText === ':root');
      if (rootIndex > -1 && !global) {
        const rootCssRool = constructedStylesheet.cssRules[rootIndex];
        const cssHostText = rootCssRool.cssText.replace(':root', ':host');
        constructedStylesheet.deleteRule(rootIndex);
        constructedStylesheet.insertRule(cssHostText);
      }

      this.repository.set(url, constructedStylesheet);
    } catch (error) {
      console.error('Error fetching stylesheet', error);
    }
  }
}
