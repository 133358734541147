import { ThemeManager } from '.';

export class ScopedThemeManager {
  private themeManager: ThemeManager;
  private isExternal: boolean;

  constructor(themeManager: ThemeManager, isExternal: boolean) {
    this.themeManager = themeManager;
    this.isExternal = isExternal;
  }

  async addCSS(url: string | string[], global = false) {
    if (this.isExternal && global) {
      console.warn(
        'Adding global styles is not allowed for 3rd party microfrontends',
      );
      return;
    }

    return this.themeManager.addCSS(url, global);
  }

  getStyles(urls: string[], includeGlobals = true): CSSStyleSheet[] {
    return this.themeManager.getStyles(urls, includeGlobals);
  }
}
