import { DockContext } from '../../context';
import { DockUserUpdateContextMessage } from '../../types';
import { AuthService } from '../auth.service';

export const DOCK_USER_UPDATE_EVENT = 'DOCK:user-updated';

export const addUpdateUserEventContext = (
  userManager: AuthService,
  dockContext: DockContext,
) => {
  const initialDockUserUpdateEvent: DockUserUpdateContextMessage = {
    updatedAt: null,
    expiresAt: null,
  };

  dockContext.register(DOCK_USER_UPDATE_EVENT, {
    initialValue: initialDockUserUpdateEvent,
    retain: true,
  });

  userManager?.userManager?.events.addUserLoaded(async (user) => {
    const updatedAt = Date.now();
    dockContext.publish(DOCK_USER_UPDATE_EVENT, {
      updatedAt,
      expiresAt: user.expires_at || null,
    });
  });
};
