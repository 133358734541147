import { PubSub } from '../context';
import { LinkConfiguration } from '../links-manager';
import { ApplicationLink } from '../links-manager/applicationLink';
import { Breadcrumb, BreadcrumbConfiguration } from './breadcrumb';
import { BreadcrumbFactory } from './breadcrumb-factory';
import { BreadcrumbNode } from './types';

export interface BreadcrumbNodeConfig extends BreadcrumbConfiguration {
  id?: string;
}

export const EXTERNAL_FACTORY_ID = '__external';
export const BREADCRUMBS_UPDATE_EVENT = 'dock.breadcrumbs.update';

export class BreadcrumbsManager {
  public currentBreadcrumbs: BreadcrumbNode[] = [];
  private repository: Map<string, BreadcrumbFactory> = new Map([
    [EXTERNAL_FACTORY_ID, new BreadcrumbFactory()],
  ]);

  constructor(private pubsub: PubSub) {
    pubsub.register(BREADCRUMBS_UPDATE_EVENT);
  }

  setBreadcrumbs(
    breadcrumbsConfigs: BreadcrumbNodeConfig[],
    after: number = 0,
  ) {
    const head = this.currentBreadcrumbs.slice(0, after);
    this.currentBreadcrumbs = [
      ...head,
      ...this.buildBreadcrumbs(breadcrumbsConfigs),
    ];
    this.handleCb();
  }
  addBreadcrumbs(breadcrumbsConfigs: BreadcrumbNodeConfig[]) {
    this.currentBreadcrumbs = [
      ...this.currentBreadcrumbs,
      ...this.buildBreadcrumbs(breadcrumbsConfigs),
    ];
    this.handleCb();
  }

  prependBreadcrumbs(breadcrumbsConfigs: BreadcrumbNodeConfig[]) {
    this.currentBreadcrumbs = [
      ...this.buildBreadcrumbs(breadcrumbsConfigs),
      ...this.currentBreadcrumbs,
    ];
    this.handleCb();
  }

  getCurrentBreadcrumbs() {
    return this.currentBreadcrumbs;
  }

  registerBreadcrumb(id: string, config: LinkConfiguration) {
    if (!('url' in config)) {
      const applicationLink = new ApplicationLink(config);
      const factory = new BreadcrumbFactory(applicationLink);
      this.registerBreadcrumbFactory(id, factory);
      return;
    }
    console.warn(
      `trying to register external link as breadcrumb, use built-in ${EXTERNAL_FACTORY_ID}`,
    );
  }
  private handleCb() {
    this.pubsub.publish(BREADCRUMBS_UPDATE_EVENT, {
      breadcrumbsCount: this.currentBreadcrumbs.length,
    });
  }
  private registerBreadcrumbFactory(
    id: string,
    factory: BreadcrumbFactory,
  ): void {
    if (this.repository.has(id)) {
      console.warn('Breadcrumb factory already registered');
      return;
    }
    this.repository.set(id, factory);
  }

  private getBreadcrumbFactory(id: string = EXTERNAL_FACTORY_ID) {
    return this.repository.get(id);
  }

  private createBreadcrumb(config: BreadcrumbNodeConfig) {
    const factory = this.getBreadcrumbFactory(config.id);
    if (!factory) {
      console.warn(`No breadcrumb registered with id ${config.id}`);
    }
    return factory?.build(config);
  }
  private buildBreadcrumbs(configs: BreadcrumbNodeConfig[]) {
    return configs
      .map((config) => this.createBreadcrumb(config))
      .filter((node): node is Breadcrumb => !!node);
  }
}
