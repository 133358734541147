import { z } from 'zod';

import { JSONObject } from '../../types';
import { JSONObjectSchema } from './json';

export const MicrofrontendCustomPropsSchema: z.ZodSchema<JSONObject> =
  JSONObjectSchema;
const CSSSchema = z.array(
  z.object({
    url: z.string(),
    global: z.boolean().default(false),
  }),
);

const MicrofrontendTokenExchangeSchema = z
  .record(z.string(), z.string())
  .and(z.object({ audience: z.string() }));

const CustomPropsSchema = MicrofrontendCustomPropsSchema.and(
  z.object({
    css: CSSSchema.optional(),
    excludeGlobalStyles: z.boolean().optional(),
    tokenExchange: MicrofrontendTokenExchangeSchema.optional(),
  }),
);

export const MicrofrontendTypesEnum = z.enum([
  'application',
  'parcel',
  'utility',
  'widget',
]);

const ModuleSchema = z.object({
  id: z.string(),
  url: z.string(),
});

export const UtilitySchema = ModuleSchema.extend({
  type: MicrofrontendTypesEnum.extract(['utility']),
  customProps: MicrofrontendCustomPropsSchema.optional(),
});

export type Utility = z.infer<typeof UtilitySchema>;

export const MicrofrontendSchema = z.object({
  id: z.string(),
  url: z.string(),
  type: MicrofrontendTypesEnum.exclude(['application', 'utility']),
  customProps: CustomPropsSchema.optional(),
  shadow: z.boolean().default(true),
  targetElement: z.string().optional(),
});
export const ApplicationMicrofrontendSchema = MicrofrontendSchema.extend({
  path: z.string(),
  type: MicrofrontendTypesEnum.extract(['application']),
});
export const MicrofrontendTypes = MicrofrontendTypesEnum.enum;
export const MicrofrontendConfigurationSchema = z.discriminatedUnion('type', [
  MicrofrontendSchema,
  UtilitySchema,
  ApplicationMicrofrontendSchema,
]);
